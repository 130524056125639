import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';

import { UserService } from '../providers/user.service';
import { AuthService } from '../providers/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'accompanying-person-details',
    templateUrl: './accompanyingpersondetails.component.html',
    styleUrls: ['./accompanyingpersondetails.component.scss']
})

export class AccompanyingPersonDetailsComponent implements OnInit {

    public isSafari:boolean = false;
    public accompanying_person_form: FormGroup;
    public full_name: AbstractControl;
    public ic_num: AbstractControl;
    public birth_date: AbstractControl;
    public contact_num: AbstractControl;
    //public email: AbstractControl;
    public meal: AbstractControl;
    public meal_other: AbstractControl;
    public passport_num: AbstractControl;
    public passport_img_url: AbstractControl;
    public passport_expiry_date: AbstractControl;
    public salutation: AbstractControl;
    public gender: AbstractControl;
    public bed_type_id: AbstractControl;
    public accompanying_package_id: AbstractControl;
    public roomate_agent_code: AbstractControl;
    public preferred_roommate: AbstractControl;

    public is_meal_other: boolean = false;
    public is_nominate: boolean = false;
    public default_attendance: 1;
    submitted = false;
    is_form_error = false;
    update_data = false;
    has_accompanying_person: boolean;
    local_user_data = JSON.parse(localStorage.getItem('user_data'));

    accompanying_room_data_list: any = [];
    user_data: any;
    form_data: any = {
        "person": [
            {
                "salutation": null,
                "full_name": null,
                "gender": null,
                "ic_num": null,
                "birth_date": null,
                "contact_num": null,
                "email": null,
                "passport_num": null,
                "passport_img_url": null,
                "passport_expiry_date": null,
                "meal": null,
                "meal_other": null,
                "bed_type_id": null,
                "accompanying_package": {
                    "id": 1
                },
                "roomate_agent_code": null,
                "preferred_roommate": null
            },
            {
                "salutation": null,
                "full_name": null,
                "gender": null,
                "ic_num": null,
                "birth_date": null,
                "contact_num": null,
                "email": null,
                "passport_num": null,
                "passport_img_url": null,
                "passport_expiry_date": null,
                "meal": null,
                "meal_other": null,
                "bed_type_id": null,
                "accompanying_package": {
                    "id": 1
                },
                "roomate_agent_code": null,
                "preferred_roommate": null
            }
        ]
    };

    public is_typeError: any;
    public is_sizeError: any;
    constructor(
        private fb : FormBuilder,
        private router : Router,
        private user_service : UserService,
        private auth : AuthService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit() {
        // Safari 3.0+ "[object HTMLElementConstructor]"
        this.isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

        this.auth.getSystemStatusData();
        if (!this.auth.checkLogin()) {
            this.router.navigate(['login']);
        } else {

        this.getAccompanyingPackageList();
        this.getUserData();
        this.getFormData();

        this.accompanying_person_form = this.fb.group({
            'full_name': new FormControl ('', [Validators.required]),
            'ic_num': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            'birth_date': new FormControl ('', [Validators.required, Validators.pattern('^[0-9/,+,-]+$')]),
            'contact_num': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            //'email': new FormControl ('', [Validators.required]),
            'meal': new FormControl ('', [Validators.required]),
            'meal_other': new FormControl (null),
            'passport_num': new FormControl ('', [Validators.required]),
            'passport_expiry_date': new FormControl ('', [Validators.required, Validators.pattern('^[0-9/,+,-]+$')]),
            'preferred_roommate': new FormControl (''),
            'salutation': new FormControl ('', [Validators.required]),
            'gender': new FormControl ('', [Validators.required]),
            'bed_type_id': new FormControl ('1', [Validators.required]),
            'accompanying_package_id': new FormControl ('', [Validators.required]),
            'roomate_agent_code': new FormControl (''),
          });
        }
    }

    // Access to form fields
    get f() { return this.accompanying_person_form.controls; }

    changeMeal(type) {
        if(type == 'other') {
            this.is_meal_other = true;
            this.accompanying_person_form.setControl('meal_other', new FormControl(this.form_data.person[1].meal_other, Validators.required));
        } else {
            this.is_meal_other = false;
            this.accompanying_person_form.setControl('meal_other', new FormControl(''));
            this.accompanying_person_form.patchValue({
                meal_other: '' });
        }
    }

    readUrl(event: any) {
        if (event.target.files && event.target.files[0]) {
			if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
				this.is_typeError = false;
				var file = event.target.files[0];
				if (file.size > 2000000) {
					this.is_sizeError = true;
				} else {
					this.is_sizeError = false;
					this.accompanying_person_form.addControl('passport_img_url', new FormControl (file, [Validators.required]));
				}
			} else {
				this.is_typeError = true;
				this.is_sizeError = false;
			}
		}
    }

    changePreferredRoommate(value) {
        if(value) {
            this.is_nominate = true;
            this.accompanying_person_form.setControl('preferred_roommate', new FormControl('', Validators.required));
        } else {
            this.is_nominate = false;
            this.accompanying_person_form.setControl('preferred_roommate', new FormControl(''));
        }
    }

    submitAccompanyingPerson() {
        this.submitted = true;

        if (this.accompanying_person_form.valid) {
            this.spinner.show();

            if (this.update_data) {
                this.user_service.putAccompanyingPerson(this.accompanying_person_form.value, this.form_data.form_id, this.form_data.parent_id, this.form_data.person_id).then(data => {
                    this.router.navigate(['terms-condition']);
                    this.spinner.hide();
                  }, error => {
                    this.spinner.hide();
                    alert("You have errors on one or more fields above, please check again.")
                  });
            } else {
                this.user_service.postAccompanyingPerson(this.accompanying_person_form.value, this.form_data.form_id, this.form_data.parent_id).then(data => {
                    this.router.navigate(['terms-condition']);
                    this.spinner.hide();
                  }, error => {
                    this.spinner.hide();
                    alert("You have errors on one or more fields above, system rejected please check again.")
                  });
            }

        } else {
            alert("You have errors on one or more fields above, please check again.")
        }
    }

    getFormData() {
        this.user_service.getFormListDataByUserID(this.local_user_data['id']).then(data => {
                this.has_accompanying_person = data['data'][0].user.has_accompanying_person == 1 ? true : false;
                if(data['data'][0]['person'].length < 1){
                    this.router.navigate(['/personal-details']);
                }
                if(data['data'][0]['person'].length > 1) {
                    /* With saved step 2 data */
                    this.update_data = true;
                    this.form_data =  {
                        "form_id": data['data'][0].id,
                        "parent_id":  data['data'][0].person[0].id,
                        "person_id": data['data'][0].person[1].id,
                        "person": [
                            {
                                "salutation": data['data'][0].person[0].salutation,
                                "full_name": data['data'][0].person[0].full_name,
                                "gender": data['data'][0].person[0].gender,
                                "ic_num": data['data'][0].person[0].ic_num,
                                "birth_date": data['data'][0].person[0].birth_date,
                                "contact_num": data['data'][0].person[0].contact_num,
                                "email": data['data'][0].person[0].email,
                                "passport_num": data['data'][0].person[0].passport_num,
                                "passport_img_url": data['data'][0].person[0].passport_img_url,
                                "passport_expiry_date": data['data'][0].person[0].passport_expiry_date,
                                "meal": data['data'][0].person[0].meal,
                                "meal_other": data['data'][0].person[0].meal_other,
                                "bed_type_id": data['data'][0].person[0].bed_type.id,
                                "preferred_roommate": data['data'][0].person[0].preferred_roommate,
                                "roomate_agent_code": data['data'][0].person[0].roomate_agent_code
                            },
                            {
                                "salutation": data['data'][0].person[1].salutation,
                                "full_name": data['data'][0].person[1].full_name,
                                "gender": data['data'][0].person[1].gender,
                                "ic_num": data['data'][0].person[1].ic_num,
                                "birth_date": data['data'][0].person[1].birth_date,
                                "contact_num": data['data'][0].person[1].contact_num,
                                "email": data['data'][0].person[1].email,
                                "passport_num": data['data'][0].person[1].passport_num,
                                "passport_img_url": data['data'][0].person[1].passport_img_url,
                                "passport_expiry_date": data['data'][0].person[1].passport_expiry_date,
                                "meal": data['data'][0].person[1].meal,
                                "meal_other": data['data'][0].person[1].meal_other,
                                "bed_type_id": data['data'][0].person[1].bed_type.id,
                                "preferred_roommate": data['data'][0].person[1].preferred_roommate,
                                "roomate_agent_code": data['data'][0].person[1].roomate_agent_code,
                                "accompanying_package": {
                                    "id" : data['data'][0].person[1].accompanying_package.id
                                }
                            }
                        ]
                    };

                    this.accompanying_person_form.patchValue({
                        full_name: this.form_data.person[1].full_name,
                        ic_num: this.form_data.person[1].ic_num,
                        birth_date: this.form_data.person[1].birth_date,
                        contact_num: this.form_data.person[1].contact_num,
                        //email: this.form_data.person[1].email,
                        meal: this.form_data.person[1].meal,
                        meal_other: this.form_data.person[1].meal_other,
                        passport_num: this.form_data.person[1].passport_num,
                        passport_expiry_date: this.form_data.person[1].passport_expiry_date,
                        preferred_roommate: this.form_data.person[1].preferred_roommate,
                        roomate_agent_code: this.form_data.person[1].roomate_agent_code,
                        salutation: this.form_data.person[1].salutation,
                        gender: this.form_data.person[1].gender,
                        bed_type_id: this.form_data.person[1].bed_type_id,
                        accompanying_package_id: this.form_data.person[1].accompanying_package.id
                    })

                    this.is_meal_other = this.form_data.person[1].meal == 'other' ? true : false;
                } else {
                    /* without step 2 save data */
                    this.form_data["form_id"] = data['data'][0].id;
                    this.form_data["parent_id"] = data['data'][0].person[0].id;
                }
        });
    }

    getUserData() {
        this.user_service.getUserData(this.local_user_data['id']).then(data => {
            this.user_data = data;
            if(data['is_submit']){
                this.router.navigate(['account-summary']);
            }
        });
    }

    getAccompanyingPackageList() {
        this.user_service.getAccompanyingPackageList().then(data => {
            this.accompanying_room_data_list= data['data'];
        });
    }

    doLogout() {
        this.auth.logout();
    }
}
