import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';

import { UserService } from '../providers/user.service';

@Component({
    selector: 'update-personal-details',
    templateUrl: './updatepersonaldetails.component.html',
    styleUrls: ['./updatepersonaldetails.component.scss']
})

export class UpdatePersonalDetailsComponent implements OnInit {

    public personal_details_form: FormGroup;
    public full_name: AbstractControl;
    public ic_num: AbstractControl;
    public birth_date: AbstractControl;
    public contact_num: AbstractControl;
    public email: AbstractControl;
    public meal: AbstractControl;
    public meal_other: AbstractControl;
    public passport_num: AbstractControl;
    public passport_img_url: AbstractControl;
    public passport_expiry_date: AbstractControl;
    public preferred_roomate: AbstractControl;
    public salutation: AbstractControl;
    public gender: AbstractControl;
    public bed_type_id: AbstractControl;
    public emergency_contact_name: AbstractControl;
    public emergency_contact_num: AbstractControl;
    public emergency_contact_relationship: AbstractControl;

    public is_meal_other: boolean = false;
    public is_nominate: boolean = false;
    public default_attendance: 1;
    submitted = false;
    is_form_error = false;

    local_user_data = JSON.parse(localStorage.getItem('user_data'));
    form_data: any;

    constructor(
        private fb : FormBuilder,
        private router : Router,
        private user_service : UserService
    ) { }

    ngOnInit() {
        this.getFormData();
        this.personal_details_form = this.fb.group({
            'full_name': new FormControl ('', [Validators.required]),
            'ic_num': new FormControl ('', [Validators.required]),
            'birth_date': new FormControl ('', [Validators.required]),
            'contact_num': new FormControl ('', [Validators.required]),
            'email': new FormControl ('', [Validators.required]),
            'meal': new FormControl ('', [Validators.required]),
            'meal_other': new FormControl (''),
            'passport_num': new FormControl ('', [Validators.required]),
            'passport_img_url': new FormControl (''),
            'passport_expiry_date': new FormControl ('', [Validators.required]),
            'preferred_roomate': new FormControl (''),
            'salutation': new FormControl ('', [Validators.required]),
            'gender': new FormControl ('', [Validators.required]),
            'bed_type_id': new FormControl ('', [Validators.required]),
            'emergency_contact_name': new FormControl ('', [Validators.required]),
            'emergency_contact_num': new FormControl ('', [Validators.required]),
            'emergency_contact_relationship': new FormControl ('', [Validators.required])
          });
    }

    // Access to form fields
    get f() { return this.personal_details_form.controls; }

    changeMeal(type) {
        this.is_meal_other = type == 'other' ? true : false;
    }

    changePreferredRoommate(value) {
        this.is_nominate = value;
    }

    submitToLogin() { 
        //this.submitted = true;
    
        if (this.personal_details_form.valid) {
        //  this.spinner.show();
        //   this.auth.login(this.login_form.value).then(data => {
        //       this.spinner.hide();
        //       this.is_form_error = false;
        //       this.router.navigate(['account-summary']);
        //     }, error => {
        //       this.is_form_error = true;
        //       this.spinner.hide();
        //   });
        } else {
            //this.global.validateAllFields(this.login_form);
        }
    }

    getFormData() {
        this.user_service.getFormListDataByUserID(this.local_user_data['id']).then(data => {
            if(data['data'].length > 0) {
                this.form_data =  data['data'][0];
                this.setFormValue();
            } else {
                this.router.navigate(['personal-details']);
            }
        });
    }

    setFormValue() {
        this.personal_details_form.patchValue({
            full_name: this.form_data.person[0].full_name,
            ic_num: this.form_data.person[0].ic_num,
            birth_date: this.form_data.person[0].birth_date,
            contact_num: this.form_data.person[0].contact_num,
            email: this.form_data.person[0].email,
            meal: this.form_data.person[0].meal,
            meal_other: this.form_data.person[0].meal_other,
            passport_num: this.form_data.person[0].passport_num,
            passport_expiry_date: this.form_data.person[0].passport_expiry_date,
            preferred_roomate: this.form_data.person[0].preferred_roomate,
            salutation: this.form_data.person[0].salutation,
            gender: this.form_data.person[0].gender,
            bed_type_id: this.form_data.person[0].bed_type_id,
            emergency_contact_name: this.form_data.person[0].emergency_contact_name,
            emergency_contact_num: this.form_data.person[0].emergency_contact_num,
            emergency_contact_relationship: this.form_data.person[0].emergency_contact_relationship

        })
    }
}
