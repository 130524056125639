import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Router } from '@angular/router';
import { API_URL, GRANT_TYPE, CLIENT_ID, CLIENT_SECRET } from '../config/constant';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { ApiService } from './api.service';

declare var $: any;

@Injectable()
export class SubscriptionService {

    constructor(
        private http: Http,
        public apiService: ApiService,
        public router: Router,
    ) { }

    postSubscriptionForm(values) {
        return new Promise((resolve, reject) => {
          let formData = new FormData();

          if (values.travel_date_id) {
              formData.append('travel_date_id', values.travel_date_id);
          }

          if (values.contact_num) {
              formData.append('contact_num', values.contact_num);
          }

          this.apiService.request('POST', '/subscription', formData).then((result: any) => {
            let data = result.data;
            resolve();
          }, error => {
            reject(error);
          });
        });
    }
}
