import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Router } from '@angular/router';
import { API_URL, GRANT_TYPE, CLIENT_ID, CLIENT_SECRET } from '../config/constant';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { ApiService } from './api.service';

declare var $: any;

@Injectable()
export class UserService {

  constructor(
    private http: Http,
    public apiService: ApiService,
    public router: Router,

  ) { }

  getUserData(user_id) {
    return new Promise((resolve, reject) => {
      this.apiService.request('GET', '/user/' + user_id, []).then((result: any) => {
        resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  updateUserData(values, user_id, submit = false) {
      let data = {}

      if (values.status) {
          data['status'] = values.status
      }

      if (values.is_attend) {
          data['is_attend'] = values.is_attend;
      }

      if (values.is_submit || submit) {
          data['is_submit'] = 1;
      }

      return new Promise((resolve, reject) => {
        this.apiService.request('PUT', '/user/' + user_id, data).then((result: any) => {
          let data = result.data;
          resolve();
        }, error => {
          reject(error);
        });
      });
  }

  getFormListDataByUserID(user_id) {
    return new Promise((resolve, reject) => {
      this.apiService.request('GET', '/form?user=' + user_id, []).then((result: any) => {
        resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  postPersonalDetails(values) {
      let formData = new FormData();

      formData.append('full_name', values.full_name);
      formData.append('ic_num', values.ic_num);
      formData.append('birth_date', values.birth_date);
      formData.append('contact_num', values.contact_num);
      //formData.append('email', values.email);
      formData.append('meal', values.meal);

      if(values.meal_other){
        formData.append('meal_other', values.meal_other);
      }

      if (values.passport_img_url) {
          formData.append('passport_img_url', values.passport_img_url);
      }

      formData.append('passport_num', values.passport_num);
      formData.append('passport_expiry_date', values.passport_expiry_date);
      formData.append('emergency_contact_name', values.emergency_contact_name);
      formData.append('emergency_contact_num', values.emergency_contact_num);
      formData.append('emergency_contact_relationship', values.emergency_contact_relationship);
      formData.append('salutation', values.salutation);
      formData.append('gender', values.gender);

      return new Promise((resolve, reject) => {
          this.apiService.request('POST', '/form', formData).then((result: any) => {
              let data = result.data;
              resolve();
          }, error => {
              reject(error);
          });
      });
  }

  postAccompanyingPerson(values,form_id,parent_id) {
      let formData = new FormData();

      formData.append('form_id', form_id);
      formData.append('parent_id', parent_id);

      formData.append('full_name', values.full_name);
      formData.append('ic_num', values.ic_num);
      formData.append('birth_date', values.birth_date);
      formData.append('contact_num', values.contact_num);
      //formData.append('email', values.email);
      formData.append('meal', values.meal);

      if(values.meal_other){
        formData.append('meal_other', values.meal_other);
      }

      if(values.preferred_roommate){
        formData.append('preferred_roommate', values.preferred_roommate);
      }

      if(values.roomate_agent_code){
        formData.append('roomate_agent_code', values.roomate_agent_code);
      }

      if (values.passport_img_url) {
          formData.append('passport_img_url', values.passport_img_url);
      }

      formData.append('passport_num', values.passport_num);
      formData.append('passport_expiry_date', values.passport_expiry_date);
      formData.append('salutation', values.salutation);
      formData.append('gender', values.gender);
      formData.append('bed_type_id', values.bed_type_id);
      formData.append('accompanying_package_id', values.accompanying_package_id);

      return new Promise((resolve, reject) => {
          this.apiService.request('POST', '/person', formData).then((result: any) => {
              let data = result.data;
              resolve();
          }, error => {
              reject(error);
          });
      });
  }

  putAccompanyingPerson(values,form_id,parent_id, person_id) {

      // formData.append('form_id', form_id);
      // formData.append('parent_id', parent_id);

      // formData.append('full_name', values.full_name);
      // formData.append('ic_num', values.ic_num);
      // formData.append('birth_date', values.birth_date);
      // formData.append('contact_num', values.contact_num);
      // formData.append('email', values.email);
      // formData.append('meal', values.meal);

      // if(values.meal_other){
      //   formData.append('meal_other', values.meal_other);
      // }


      // if (values.passport_img_url) {
      //     formData.append('passport_img_url', values.passport_img_url);
      // }

      // formData.append('passport_num', values.passport_num);
      // formData.append('passport_expiry_date', values.passport_expiry_date);
      // formData.append('salutation', values.salutation);
      // formData.append('gender', values.gender);
      // formData.append('bed_type_id', values.bed_type_id);
      // formData.append('accompanying_package_id', values.accompanying_package_id);

      var formData = {
        'form_id': values.form_id,
        'parent_id': values.parent_id,
        'full_name': values.full_name,
        'ic_num': values.ic_num,
        'birth_date': values.passpobirth_datert_num,
        'contact_num': values.contact_num,
        //'email': values.email,
        'meal': values.meal,
        'passport_img_url': values.passport_img_url,
        'passport_num': values.passport_num,
        'passport_expiry_date': values.passport_expiry_date,
        'salutation': values.salutation,
        'gender': values.gender,
        'bed_type_id': values.bed_type_id,
        'accompanying_package_id': values.accompanying_package_id        
      }

      if(values.meal_other) {
        formData['meal_other'] = values.meal_other;
      } else {
        delete formData['meal_other']; 
      }

      if(values.preferred_roommate) {
        formData['preferred_roommate'] = values.preferred_roommate;
      } else {
        delete formData['preferred_roommate']; 
      }

      if(values.roomate_agent_code) {
        formData['roomate_agent_code'] = values.roomate_agent_code;
      } else {
        delete formData['roomate_agent_code']; 
      }

      return new Promise((resolve, reject) => {
          this.apiService.request('PUT', '/person/' + person_id, formData).then((result: any) => {
              let data = result.data;
              resolve();
          }, error => {
              reject(error);
          });
      });
  }

  getBedTypeList() {
    return new Promise((resolve, reject) => {
      this.apiService.request('GET', '/bed_type', []).then((result: any) => {
        resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  getAccompanyingPackageList() {
    return new Promise((resolve, reject) => {
      this.apiService.request('GET', '/accompanying_package', []).then((result: any) => {
        resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  submitForm(form_id, values) {
    var formData = {
      'current_step' : 4
    }

    if(values.special_request) {
      formData['special_request'] = values.special_request;
    }
    
    if(values.has_insurance) {
        formData['has_insurance'] = values.has_insurance;
        formData['insurance_type'] = values.insurance_type;
        formData['insured_person_address'] = values.insured_person_address;
        formData['beneficiary_person_name'] = values.beneficiary_person_name;
        formData['beneficiary_person_ic'] = values.beneficiary_person_ic;
        formData['beneficiary_person_relationship'] = values.beneficiary_person_relationship;
        formData['beneficiary_person_address'] = values.beneficiary_person_address;
    } else {
      formData['has_insurance'] = 0;
    }
    
    return new Promise((resolve, reject) => {
        this.apiService.request('PUT', '/form/' + form_id, formData).then((result: any) => {
            let data = result;
            resolve(data);
        }, error => {
            reject(error);
        });
    });
}
}
