import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../providers/user.service';
import { AuthService } from '../providers/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'personal-details',
    templateUrl: './personaldetails.component.html',
    styleUrls: ['./personaldetails.component.scss']
})

export class PersonalDetailsComponent implements OnInit {

    public send_email = null;
    public isSafari:boolean = false;
    public personal_details_form: FormGroup;
    public full_name: AbstractControl;
    public ic_num: AbstractControl;
    public birth_date: AbstractControl;
    public contact_num: AbstractControl;
    //public email: AbstractControl;
    public meal: AbstractControl;
    public meal_other: AbstractControl;
    public passport_num: AbstractControl;
    public passport_img_url: AbstractControl;
    public passport_expiry_date: AbstractControl;
    public preferred_roommate: AbstractControl;
    public salutation: AbstractControl;
    public gender: AbstractControl;
    public bed_type_id: AbstractControl;
    public emergency_contact_name: AbstractControl;
    public emergency_contact_num: AbstractControl;
    public emergency_contact_relationship: AbstractControl;

    public is_meal_other: boolean = false;
    public is_nominate: boolean;
    public default_attendance: 1;
    submitted = false;
    is_form_error = false;

    local_user_data = JSON.parse(localStorage.getItem('user_data'));

    room_data_list: any = [];
    user_data: any;
    form_data: any = {
        "emergency_contact_name": "",
        "emergency_contact_num": "",
        "emergency_contact_relationship": "",
        "person": [
            {
                "salutation": null,
                "full_name": null,
                "gender": null,
                "ic_num": null,
                "birth_date": null,
                "birth_date_unformat": null,
                "contact_num": null,
                "email": null,
                "passport_num": null,
                "passport_img_url": null,
                "passport_expiry_date": null,
                "passport_expiry_date_unformat": null,
                "meal": null,
                "meal_other": null,
            }
        ]
    };

    public is_typeError: any;
    public is_sizeError: any;
    constructor(
        private fb : FormBuilder,
        private router : Router,
        private user_service : UserService,
        private auth : AuthService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        setTimeout(() => this.spinner.show(), 1);

        /* Check Safari */
        //this.isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

        this.auth.getSystemStatusData();
        if (!this.auth.checkLogin()) {
            this.router.navigate(['login']);
        } else {

        this.getUserData();
        this.getFormData();

        this.personal_details_form = this.fb.group({
            'full_name': new FormControl ('', [Validators.required]),
            'ic_num': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            'birth_date_unformat': new FormControl ('', [Validators.required]),
            'passport_expiry_date_unformat': new FormControl ('', [Validators.required]),
            'birth_date': new FormControl ('', [Validators.required]),
            'contact_num': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            'meal': new FormControl ('', [Validators.required]),
            'meal_other': new FormControl (''),
            'passport_num': new FormControl ('', [Validators.required]),
            'passport_expiry_date': new FormControl (''),
            'salutation': new FormControl ('', [Validators.required]),
            'gender': new FormControl ('', [Validators.required]),
            'emergency_contact_name': new FormControl ('', [Validators.required]),
            'emergency_contact_num': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            'emergency_contact_relationship': new FormControl ('', [Validators.required])
          });
        }
    }

    // Access to form fields
    get f() { return this.personal_details_form.controls; }

    changeMeal(type) {
        if(type == 'other') {
            this.is_meal_other = true;
            this.personal_details_form.setControl('meal_other', new FormControl(this.form_data.person[0].meal_other, Validators.required));
        } else {
            this.is_meal_other = false;
            this.personal_details_form.setControl('meal_other', new FormControl(''));
        }
    }

    readUrl(event: any) {
        if (event.target.files && event.target.files[0]) {
			if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg") {
				this.is_typeError = false;
				var file = event.target.files[0];
				if (file.size > 2000000) {
					this.is_sizeError = true;
				} else {
					this.is_sizeError = false;
					this.personal_details_form.addControl('passport_img_url', new FormControl (file, [Validators.required]));
				}
			} else {
				this.is_typeError = true;
				this.is_sizeError = false;
			}
		}
    }

    changePreferredRoommate(value) {
        if(value) {
            this.is_nominate = true;
            this.personal_details_form.setControl('preferred_roommate', new FormControl(this.form_data.person[0].preferred_roommate, Validators.required));
            this.personal_details_form.patchValue({preferred_roomate: this.form_data.person[0].preferred_roommate});
        } else {
            this.is_nominate = false;
            this.personal_details_form.setControl('preferred_roommate', new FormControl(''));
            this.personal_details_form.patchValue({preferred_roommate: null});
        }
    }

    submitPersonalDetails() {
        if(typeof this.personal_details_form.value['birth_date_unformat'] == 'object'){
            // if(this.personal_details_form.value['birth_date_unformat']) {
            //     this.personal_details_form.setControl('birth_date_unformat', new FormControl(''));
            // }
            this.personal_details_form.patchValue({
                'birth_date': this.personal_details_form.value['birth_date'] == '' ? this.formatDateFromPicker(this.personal_details_form.value['birth_date_unformat']) : this.personal_details_form.value['birth_date']
            });
            delete(this.personal_details_form.value['birth_date_unformat']);
        } else {
            if(this.personal_details_form.value['birth_date_unformat']) {
                this.personal_details_form.setControl('birth_date_unformat', new FormControl(''));
            }

            this.personal_details_form.patchValue({
                birth_date_unformat: {
                    "year":  parseInt(this.personal_details_form.value['birth_date'].substring(0,4), 10),
                    "month": parseInt(this.personal_details_form.value['birth_date'].substring(5,7), 10),
                    "day": parseInt(this.personal_details_form.value['birth_date'].substring(8,10), 10)
                }
            });
        }

        if(typeof this.personal_details_form.value['passport_expiry_date_unformat'] == 'object'){
            this.personal_details_form.patchValue({
                'passport_expiry_date': this.personal_details_form.value['passport_expiry_date'] == '' ? this.formatDateFromPicker(this.personal_details_form.value['passport_expiry_date_unformat']) : this.personal_details_form.value['passport_expiry_date']
            });
            delete(this.personal_details_form.value['passport_expiry_date_unformat']);
        } else {
            if(this.personal_details_form.value['passport_expiry_date_unformat']) {
                this.personal_details_form.setControl('passport_expiry_date_unformat', new FormControl(''));
            }
            this.personal_details_form.patchValue({
                passport_expiry_date_unformat: {
                    "year":  parseInt(this.personal_details_form.value['passport_expiry_date'].substring(0,4), 10),
                    "month": parseInt(this.personal_details_form.value['passport_expiry_date'].substring(5,7), 10),
                    "day": parseInt(this.personal_details_form.value['passport_expiry_date'].substring(8,10), 10)
                }
            });
        }

        this.submitted = true;

        if (this.personal_details_form.valid) {
            this.spinner.show();
            this.user_service.postPersonalDetails(this.personal_details_form.value).then(data => {
            this.router.navigate(['terms-condition']);
            }, error => {
                this.spinner.hide();
                alert("There are errors while submitting, please try again.")
            });
        } else {

            if (this.f.salutation.errors){
                alert("Salutation is a required field");
            } else if(this.f.gender.errors){
                alert("Gender is a required field");
            } else if (this.f.full_name.errors){
                alert("Full name is a required field");
            } else if (this.f.ic_num.errors){
                alert("IC number is a required field");
            } else if(this.f.birth_date_unformat.errors){
                alert("Birth date must be a valid date");
            }
            // else if (this.f.birth_date.errors){
            //     alert(this.string(this.f.birth_date.errors));
            // }
            // else if (this.f.passport_expiry_date.errors){
            //     alert(this.string(this.f.passport_expiry_date.errors));
            // }
            else if (this.f.meal.errors){
                alert("Meal preference is a required field");
            } else if (this.f.passport_num.errors){
                alert("Passport number is a required field");
            } else if (this.f.passport_expiry_date_unformat.errors){
                alert("Passport expiry date must be a valid date");
                // alert(this.string(this.f.passport_expiry_date_unformat.errors));
            } else if (this.f.emergency_contact_name.errors){
                alert("Emergency contact name is a required field");
            } else if (this.f.emergency_contact_num.errors){
                alert("Emergency contact number is a required field");
            } else if (this.f.emergency_contact_relationship.errors){
                alert("Emergency contact relationship is a required field");
            } else if (this.is_sizeError){
                alert("Passport image has exceeded maximum size of 2mb");
            } else if (this.is_typeError){
                alert("Passport image can only be JPEG/JPG file type");
            } else {
                alert("You have errors on one or more fields above, please check again.");
            }
        }
    }

    formatDateFromPicker(date) {
        if(date) {
            return date['year'] + '-' + date['month'] + '-' + date['day'];
        } else {
            return null;
        }
    }

    formatDateFromPickerToObject(date) { console.log(date);
        if(date) {
            var new_date = {
                "year":  parseInt(date.substring(0,4), 10),
                "month": parseInt(date.substring(5,7), 10),
                "day": parseInt(date.substring(8,10), 10)
            };
            console.log(new_date);
            return new_date;
        } else {
            return null;
        }
    }

    getFormData() {
        this.user_service.getFormListDataByUserID(this.local_user_data['id']).then(data => {
            if(data['data'].length > 0) {
                let expiry_date = data['data'][0].person[0].passport_expiry_date;
                let birth_date = data['data'][0].person[0].birth_date;
                this.form_data =  {
                    "emergency_contact_name": data['data'][0].emergency_contact_name,
                    "emergency_contact_num": data['data'][0].emergency_contact_num,
                    "emergency_contact_relationship": data['data'][0].emergency_contact_relationship,
                    "person": [
                        {
                            "salutation": data['data'][0].person[0].salutation,
                            "full_name": data['data'][0].person[0].full_name,
                            "gender": data['data'][0].person[0].gender,
                            "ic_num": data['data'][0].person[0].ic_num,

                            "birth_date": data['data'][0].person[0].birth_date,
                            "birth_date_unformat": data['data'][0].person[0].birth_date,

                            "contact_num": data['data'][0].person[0].contact_num,
                            "email": data['data'][0].person[0].email,
                            "passport_num": data['data'][0].person[0].passport_num,
                            "passport_img_url": data['data'][0].person[0].passport_img_url,

                            "passport_expiry_date_unformat": data['data'][0].person[0].passport_expiry_date,
                            "passport_expiry_date": data['data'][0].person[0].passport_expiry_date,

                            "meal": data['data'][0].person[0].meal,
                            "meal_other": data['data'][0].person[0].meal_other
                        }
                    ]
                };

                this.personal_details_form.patchValue({
                    full_name: this.form_data.person[0].full_name,
                    ic_num: this.form_data.person[0].ic_num,
                    birth_date_unformat: {
                        "year":  parseInt(birth_date.substring(0,4), 10),
                        "month": parseInt(birth_date.substring(5,7), 10),
                        "day": parseInt(birth_date.substring(8,10), 10)
                    },
                    birth_date: '',
                    //birth_date: this.form_data.person[0].birth_date,
                    contact_num: this.form_data.person[0].contact_num,
                    meal: this.form_data.person[0].meal,
                    meal_other: this.form_data.person[0].meal_other,
                    passport_num: this.form_data.person[0].passport_num,
                    passport_expiry_date_unformat: {
                        "year":  parseInt(expiry_date.substring(0,4), 10),
                        "month": parseInt(expiry_date.substring(5,7), 10),
                        "day": parseInt(expiry_date.substring(8,10), 10)
                    },
                    //passport_expiry_date: data['data'][0].person[0].passport_expiry_date,
                    passport_expiry_date: '',
                    salutation: this.form_data.person[0].salutation,
                    gender: this.form_data.person[0].gender,
                    emergency_contact_name: this.form_data.emergency_contact_name,
                    emergency_contact_num: this.form_data.emergency_contact_num,
                    emergency_contact_relationship: this.form_data.emergency_contact_relationship
                })
            }
            this.spinner.hide()
        });
    }

    getUserData() {
        this.user_service.getUserData(this.local_user_data['id']).then(data => {
            this.user_data = data;
            if(data['is_submit']){
                this.router.navigate(['account-summary']);
            }
        });
    }

    doLogout() {
        this.auth.logout();
    }

    open(content, type, modalDimension) {
        this.modalService.open(content, { centered: true }).result.then((result) => {});
    }

    string(string) {
        return JSON.stringify(string);
    }
}
