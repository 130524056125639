import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Router } from '@angular/router';
import { API_URL, GRANT_TYPE, CLIENT_ID, CLIENT_SECRET } from '../config/constant';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { ApiService } from './api.service';
// import { AngularFireDatabase } from 'angularfire2/database';
// import { AngularFireAuth } from 'angularfire2/auth';
// import * as firebase from 'firebase';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';

declare var $: any;

@Injectable()
export class AuthService {

  constructor(
    private http: Http,
    public apiService: ApiService,
    public router: Router,

  ) { }

  login(data) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append('grant_type', GRANT_TYPE);
      formData.append('client_id', CLIENT_ID);
      formData.append('client_secret', CLIENT_SECRET);
      formData.append('agent_code', data.agent_code);
      formData.append('agent_id', data.agent_id);

      this.apiService.request('POST', '/login', formData).then((result: any) => {
        let data = result.data;

        localStorage.setItem('token', data.access_token);
        localStorage.setItem('is_agree_tnc', '0');
        localStorage.setItem('user_data', JSON.stringify(data.user));
        //localStorage.setItem('username', data.user.first_name + ' ' + data.user.last_name);

        resolve();
      }, error => {
        reject(error);
      });
    });
  }

  logout() {
    this.clearStorageItem();

    this.router.navigate(['']);
  }

  logoutWithoutNavigate() {
    this.clearStorageItem();
  }

  clearStorageItem() {
    localStorage.clear();
  }

  checkLogin() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      this.router.navigate(['']);
    }
  }

  getSystemStatusData() {
    return new Promise((resolve, reject) => {
      this.apiService.request('GET', '/system/status', []).then((result: any) => {
          if(result['system_settings'] != 'online') {
              this.goToOfflinepage();
          }
          resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  goToOfflinepage() {
    this.router.navigate(['registration-closed']);
  }

}
