import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';

import { AuthService } from '../providers/auth.service';
import { TravelService } from '../providers/travel.service';
import { UserService } from '../providers/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'account-summary',
    templateUrl: './accountsummary.component.html',
    styleUrls: ['./accountsummary.component.scss']
})

export class AccountSummaryComponent implements OnInit {

    local_user_data = JSON.parse(localStorage.getItem('user_data'));
    is_agree = localStorage.getItem('is_agree_tnc') == '0' ? false : true;

    public send_email = null;
    public attendance_form: FormGroup;
    public subscription_form: FormGroup;
    public is_attend: AbstractControl;
    public has_accompanying_person: AbstractControl;
    public travel_date_id: AbstractControl;

    public user_data: any;
    public is_submit = false;
    public default_attendance: 1;

    checked_tnc = false;
    submitted = false;

    closeResult: string;
    subscription_submitted = false;
    @ViewChild('content') content: any;

    constructor(
        private fb : FormBuilder,
        private subscription_fb: FormBuilder,
        private router : Router,
        private route: ActivatedRoute,
        private auth : AuthService,
        private user_service : UserService,
        private spinner: NgxSpinnerService,
    ) { }

    ngOnInit() {
        setTimeout(() => this.spinner.show(), 1);

        this.send_email = this.route.snapshot.paramMap.get('sent_email') ? this.route.snapshot.paramMap.get('sent_email') : null;

        this.auth.getSystemStatusData();
        if (!this.auth.checkLogin()) {
            this.router.navigate(['login']);
        }

        this.getUserData();
        this.attendance_form = this.fb.group({
            'is_attend': new FormControl (null, [Validators.required])
        });
        this.subscription_form = this.subscription_fb.group({
            'contact_num': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            'travel_date_id': new FormControl ('')
        });
    }

    // Access to form fields
    get f() { return this.attendance_form.controls; }

    getUserData() {
        this.user_service.getUserData(this.local_user_data['id']).then(data => {
            this.user_data = data;
            this.is_submit = data['is_submit'];

            if(this.user_data['is_attend'] != null) {
                this.attendance_form.patchValue({
                    'is_attend': this.user_data['is_attend'],
                });
            }
            this.spinner.hide();
            return data;
        });
    }

    changeAttendance(attend) {}

    submitAccountSummary() {
        this.submitted = true;
        if (this.attendance_form.valid) {
            this.spinner.show();

            if(this.attendance_form.value['is_attend'] == 1) { /* Is attend */
                /* Update user attendance */
                this.user_service.updateUserData(this.attendance_form.value, this.local_user_data['id'], false).then((data: any) => {
                        alert("Note: Allow for 1 time submission only, no amendment/changes allow after click on 'CONFIRM & SUBMIT' button.");
                        this.spinner.hide();
                        this.router.navigate(['personal-details']);
                    }, error => {
                        alert("There are some errors while submitting");
                        this.spinner.hide();
                    });
            } else { /* No attend */
                /* Update user attendance & send email */
                if(confirm("You have selected not to attend the trip, are you sure?")) {
                    this.user_service.updateUserData(this.attendance_form.value, this.local_user_data['id'], true).then((data: any) => {
                        this.spinner.hide();
                        this.getUserData();
                    }, error => {
                        this.spinner.hide();
                    });
                } else {
                    this.spinner.hide();
                }
            }

        } else {
            alert("Please select your attendance");
        }
    }

    doLogout() {
        this.auth.logout();
    }

    checkTncAction() {
        this.checked_tnc = this.checked_tnc ? false : true;
    }

    agreeTnC() {
        localStorage.setItem('is_agree_tnc', '1');
        this.is_agree = true;
        alert('Note: Allow for 1 time submission only, no amendment/changes allow after click on "CONFIRM & SUBMIT" button on Step 3');
    }
}
