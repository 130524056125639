import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { API_URL, API_PREFIX } from '../config/constant';
import 'rxjs/add/operator/map';

@Injectable()
export class ApiService {
    constructor(private http: Http) {}

    createHeader() {
        const headers = new Headers;
        headers.append('Accept', 'application/json');

        if (localStorage.getItem('token') !== null) {
            headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
        }

        return headers;
    }

    request(method: string, url: string, data: any): Promise < any > {

        var methodToUse: any;
        switch (method) {
            case 'GET':
                methodToUse = RequestMethod.Get
                break;
            case 'POST':
                methodToUse = RequestMethod.Post
                break;
            case 'PUT':
                methodToUse = RequestMethod.Put
                break;
            case 'DELETE':
                methodToUse = RequestMethod.Delete
                break;
            default:
                methodToUse = RequestMethod.Get
                break;
        }

        var requestOptions = new RequestOptions({
            method: methodToUse,
            headers: this.createHeader(),
            body: data
        });

        return new Promise((resolve, reject) => {
            this.http.request(API_URL + API_PREFIX + url, requestOptions)
            .map(res => res.json())
                .subscribe(
                    // tslint:disable-next-line:no-shadowed-variable
                    data => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    });
        });
    }
}
