import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Router } from '@angular/router';
import { API_URL, GRANT_TYPE, CLIENT_ID, CLIENT_SECRET } from '../config/constant';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { ApiService } from './api.service';

declare var $: any;

@Injectable()
export class TravelService {

  constructor(
    private http: Http,
    public apiService: ApiService,
    public router: Router,

  ) { }

  getTravelDateList() {
      return new Promise((resolve, reject) => {
        this.apiService.request('GET', '/travel_date', []).then((result: any) => {
          resolve(result);
        }, error => {
          reject(error);
        });
      });
  }

  postTravelDate(values) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append('travel_date_id', values.travel_date_id);
      //formData.append('has_accompanying_person', values.has_accompanying_person);

      this.apiService.request('POST', '/set_travel_date', formData).then((result: any) => {
        let data = result.data;
        resolve();
      }, error => {
        reject(error);
      });
    });
  }

  updateTravelDate(values) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();

      formData.append('travel_date_id', values.travel_date_id);
      formData.append('has_accompanying_person', values.has_accompanying_person);

      this.apiService.request('POST', '/reset_travel_date', formData).then((result: any) => {
        let data = result.data;
        resolve();
      }, error => {
        reject(error);
      });
    });
  }
}
