import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';

import { UserService } from '../providers/user.service';
import { AuthService } from '../providers/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'terms-condition',
    templateUrl: './termsconditions.component.html',
    styleUrls: ['./termsconditions.component.scss']
})

export class TermsConditionComponent implements OnInit {

    public tnc_form: FormGroup;
    public special_request: AbstractControl;
    public is_buy_insurance: any = true;
    public insurance_type = 'Classic';
    public insured_person_address: AbstractControl;
    public beneficiary_person_name: AbstractControl;
    public beneficiary_person_ic: AbstractControl;
    public beneficiary_person_relationship: AbstractControl;
    public beneficiary_person_address: AbstractControl;

    submitted = false;

    local_user_data = JSON.parse(localStorage.getItem('user_data'));
    
    room_data_list: any = [];
    user_data: any;
    form_data: any = {
        "emergency_contact_name": "",
        "emergency_contact_num": "",
        "emergency_contact_relationship": "",
        "person": [
            {
                "salutation": null,
                "full_name": null,
                "gender": "m",
                "ic_num": null,
                "birth_date": null,
                "contact_num": null,
                "email": null,
                "passport_num": null,
                "passport_img_url": null,
                "passport_expiry_date": null,
                "meal": "normal",
                "meal_other": null,
                "bed_type_id": 1,
                "preferred_roommate": null,
                "accompanying_package": null
            }
        ]
    };

    constructor(
        private fb : FormBuilder,
        private router : Router,
        private user_service : UserService,
        private auth : AuthService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit() {
        this.auth.getSystemStatusData();
        if (!this.auth.checkLogin()) {
            this.router.navigate(['login']);
        } else {
        
        this.getUserData();
        this.getFormData();

        this.tnc_form = this.fb.group({
            'special_request': new FormControl ('', [Validators.maxLength(200)]),
            'insurance_type': new FormControl (''),
            'insured_person_address': new FormControl ('', [Validators.required]),
            'beneficiary_person_name': new FormControl ('', [Validators.required]),
            'beneficiary_person_ic': new FormControl ('', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9,+,-]+$')]),
            'beneficiary_person_relationship': new FormControl ('', [Validators.required]),
            'beneficiary_person_address': new FormControl ('', [Validators.required])
          });
        }
    }

    // Access to form fields
    get f() { return this.tnc_form.controls; }

    changeBuyInsurance(value) {
        this.is_buy_insurance = value ? 1 : 0;
        if(value == true) {
            this.is_buy_insurance = 1;
            this.tnc_form.setControl('insured_person_address', new FormControl('', [Validators.required]));
            this.tnc_form.setControl('insured_person_address', new FormControl('', [Validators.required]));
            this.tnc_form.setControl('beneficiary_person_name', new FormControl('', [Validators.required]));
            this.tnc_form.setControl('beneficiary_person_ic', new FormControl('', [Validators.required]));
            this.tnc_form.setControl('beneficiary_person_relationship', new FormControl('', [Validators.required]));
            this.tnc_form.setControl('beneficiary_person_address', new FormControl('', [Validators.required]));
        } else {
            this.is_buy_insurance = 0;
            this.tnc_form.setControl('insured_person_address', new FormControl(''));
            this.tnc_form.setControl('beneficiary_person_name', new FormControl(''));
            this.tnc_form.setControl('beneficiary_person_ic', new FormControl(''));
            this.tnc_form.setControl('beneficiary_person_relationship', new FormControl(''));
            this.tnc_form.setControl('beneficiary_person_address', new FormControl(''));
        }
    }

    changeInsuranceType(value) {
        this.insurance_type = value;
    }

    getFormData() {
        this.user_service.getFormListDataByUserID(this.local_user_data['id']).then(data => {          
            if(data['data'].length > 0) {
                this.form_data =  {
                    "id": data['data'][0].id,
                    "has_insurance": data['data'][0].has_insurance
                };
            }
        });
    }

    getUserData() {
        this.user_service.getUserData(this.local_user_data['id']).then(data => {
            this.user_data = data;
            if(data['is_submit']){
                this.router.navigate(['account-summary']);
            }
        });
    }

    submitForm() {
            this.tnc_form.value['insurance_type'] = this.insurance_type == 'Classic'?'Classic':'VIP';
            this.tnc_form.value['has_insurance'] = this.is_buy_insurance;
            this.spinner.show();

            this.user_service.submitForm(this.form_data.id, this.tnc_form.value).then(data => {
                let email = data['message'];
                //confirm("Note: Allow for 1 time submission only, no amendment/changes allow after click on 'CONFIRM & SUBMIT' button.");
                this.spinner.hide();
                this.router.navigate(['account-summary', {sent_email: email}]);
            }, error => {
                this.spinner.hide();
                alert('Error, please try again later');
            });
    }

    doLogout() {
        this.auth.logout();
    }

    confirmNextDialog() {
        this.submitted = true;
        if(this.tnc_form.valid) {
            if(confirm("Note: Allow for 1 time submission only, no amendment/changes allow after click on 'CONFIRM & SUBMIT' button.")) {   
                this.auth.getSystemStatusData().then(data => {
                if(data['system_settings'] == 'online') {   
                            this.submitForm();  
                }
            });
            }
        } else {
            alert("You have errors on one or more fields above, please check again.")
        }    
    }
}
