import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AccountSummaryComponent } from './accountsummary/accountsummary.component';
import { PersonalDetailsComponent } from './personaldetails/personaldetails.component';
import { UpdatePersonalDetailsComponent } from './updatepersonaldetails/updatepersonaldetails.component';
import { AccompanyingPersonDetailsComponent } from './accompanyingpersondetails/accompanyingpersondetails.component';
import { TermsConditionComponent } from './termsconditions/termsconditions.component';
import { RegistrationClosedComponent } from './registrationclosed/registrationclosed.component';

const routes: Routes =[
    //{ path: 'home',             component: HomeComponent },
    //{ path: 'user-profile',     component: ProfileComponent },
    //{ path: 'register',           component: SignupComponent },
    //{ path: 'landing',          component: LandingComponent },
    { path: 'login',          component: LoginComponent },
    { path: 'account-summary',          component: AccountSummaryComponent },
    { path: 'personal-details',          component: PersonalDetailsComponent },
    { path: 'update-personal-details',          component: UpdatePersonalDetailsComponent },
    { path: 'accompanying-person-details',          component: AccompanyingPersonDetailsComponent },
    { path: 'terms-condition',          component: TermsConditionComponent },
    { path: 'registration-closed',          component: RegistrationClosedComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
