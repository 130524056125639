import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';

import { AuthService } from '../providers/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  focus;
  focus1;

  public login_form: FormGroup;
  public agent_code: AbstractControl;
  public agent_id: AbstractControl;
  submitted = false;
  is_form_error = false;

  constructor(
    private fb : FormBuilder,
        private auth : AuthService,
        private router : Router,
        private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.auth.getSystemStatusData();
    if (this.auth.checkLogin()) {
      this.router.navigate(['account-summary']);
    }

    this.login_form = this.fb.group({
      'agent_code': new FormControl ('', [Validators.required]),
      'agent_id': new FormControl ('', [Validators.required])
    });

  }

  // Access to form fields
  get f() { return this.login_form.controls; }

  submitToLogin() { 
    
    this.submitted = true;

    if (this.login_form.valid) {
      this.spinner.show();
      this.auth.login(this.login_form.value).then(data => {
          this.spinner.hide();
          this.is_form_error = false;
          this.router.navigate(['account-summary']);
        }, error => {
          this.is_form_error = true;
          this.spinner.hide();
      });
    } else {
        //this.global.validateAllFields(this.login_form);
    }
}

}
