import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';

import { AuthService } from '../providers/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'registration-closed',
  templateUrl: './registrationclosed.component.html',
  styleUrls: ['./registrationclosed.component.css']
})

export class RegistrationClosedComponent implements OnInit {

  constructor(
        private spinner: NgxSpinnerService,
        public auth: AuthService,
        public router: Router
  ) { }

  ngOnInit() {
    this.auth.logoutWithoutNavigate();
    //this.auth.getSystemStatusData();
  }

}
