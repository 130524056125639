const ENV: string = 'live';
export const API_URL: string = (ENV == 'local') ? 'http://192.168.0.123:8888/travel-forte-bali-api/public/' : 'https://bali-demo-api.ant-internet.com/';
export const API_PREFIX: string = 'api/2.0';
export const GRANT_TYPE: string = 'password';
export const SCOPES: string = '';
export const CLIENT_ID: string = '2';
export const CLIENT_SECRET: string = (ENV == 'local') ? 'lzXbHeBYLuIJB7cP7wemKTqBeexvZIhPXUvIPwSW' : 'PjpnuUpfvK6ptDluPyAZLsck91m5wYGhN2gfjpDG';

/* Image upload file size */
export const IMAGE_MAX_SIZE: number = 2000000;
